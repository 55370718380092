<template>
    <centered-layout>
        <w-recovery-code-form
            :alternative-route="mfaRoute"
            :errors="recoveryAccountErrors"
            :item="{email_address: emailAddress}"
            :loading="submittingForm"
            :logo-src="clientLogo"
            :title="title"
            @submit="submit"
            v-if="!recovered"
        ></w-recovery-code-form>
        <w-form-message :logo-path="clientLogo" :title="title" v-else>
            <p class="text-xs-left">
                You have successfully recovered your account and have been logged in.
                Please note, two-factor authentication has been removed from your account in this process.
                If you would like to re-enable two-factor authentication, please do this in the 2FA section
                in the My Details page after clicking continue below.
            </p>
            <template slot="actions">
                <v-spacer></v-spacer>
                <w-btn @click="redirect" color="primary">Continue</w-btn>
            </template>
        </w-form-message>
    </centered-layout>
</template>

<script>
    // vm.client
    import qs from 'qs'
    import {mapActions, mapGetters} from 'vuex'
    import {MULTI_FACTOR_AUTH_PAGE} from '@/router/route-names'
    import CenteredLayout from '@/layouts/CenteredLayout.vue'
    import WBtn from '@wf/components/WBtn'
    import WFormMessage from '@wf/components/WFormMessage'
    import WRecoveryCodeForm from '@wf/components/WRecoveryCodeForm'

    export default {
        name: 'RecoveryPage',
        metaInfo () {
            return {
                title: this.title
            }
        },
        data: () => ({
            recoveryAccountErrors: [],
            submittingForm: false,
            recovered: false,
            recoveryResponse: {},
            mfaPage: MULTI_FACTOR_AUTH_PAGE
        }),
        props: {
            clientId: {
                type: String,
                required: true
            },
            publicKey: {
                type: String,
                required: true
            },
            emailAddress: {
                type: String,
                required: true
            },
            accountCode: {
                type: String,
                required: true
            },
            userId: {
                type: String,
                required: true
            },
            code: {
                type: String,
                required: true
            }
        },
        components: {
            CenteredLayout,
            WBtn,
            WFormMessage,
            WRecoveryCodeForm
        },
        methods: {
            ...mapActions('auth', ['recoverAccount']),
            redirect () {
                const vm = this
                const response = vm.recoveryResponse
                const query = {...response.data, ..._.omit(response, 'data')}
                const url = `${vm.redirectUrl}?${qs.stringify(query)}`
                vm.redirectUrlWithQuery = url
                window.location.replace(url)
            },
            submit (formData) {
                const vm = this
                vm.submittingForm = true
                const data = {
                    ...formData,
                    'client_id': vm.clientId,
                    'public_key': vm.publicKey,
                    'account_code': vm.accountCode,
                    'user_id': vm.userId,
                    'mfa_key': vm.code
                }
                vm.recoverAccount(data).then((response) => {
                    vm.recoveryResponse = response
                    vm.recovered = true
                    vm.submittingForm = false
                }, (response) => {
                    vm.recoveryAccountErrors = response.errors
                    vm.submittingForm = false
                })
            }
        },
        computed: {
            ...mapGetters('auth', ['clientLogo', 'redirectUrl', 'clientName']),
            title () {
                const vm = this
                const text = !vm.recovered ? 'Recover Account' : 'Account Recovered'

                return `${text} - ${vm.clientName}`
            },
            mfaRoute () {
                const vm = this
                return {
                    name: vm.mfaPage,
                    params: {
                        userId: vm.userId,
                        code: vm.code
                    }
                }
            }
        }
    }
</script>
